<template>
  <div class="common-bt-container">
    <div class="bt-item" @click="goHome">
      <svg-icon icon-class="icon-home"></svg-icon>
      <p>首页</p>
    </div>
    <div class="bt-item" @click="goBack">
      <svg-icon icon-class="icon-back"></svg-icon>
      <p>返回</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'c__common-bottom-bts',
};
</script>
<style lang="scss" scoped>
.common-bt-container {
  @include saveAreaBottom;
  background-color: #fff;
  z-index: 11;
  width: 100%;
  height: 98px;
  position: fixed;
  bottom: 0;
  left: 0;
  font-size: 32px;
  font-weight: bold;

  // .inner {
  //   line-height: 98px;
  //   position: relative;
  //   text-align: center;

  //   .svgicon {
  //     position: absolute;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     left: 32px;
  //   }
  // }
  @include flex-box;

  .bt-item {
    @include flex-box;
    justify-content: center;
    align-items: center;
    width: 100%;
    p {
      margin-left: 20px;
    }
    font-weight: bold;
    flex: 1;
    line-height: 98px;
    text-align: center;
    font-size: 32px;
  }
}
</style>
