<template>
  <div class="wr-container">
    <div class="top-bg">
      <img src="http://timeline-file.crosscrew.cc/static/wr-bg.png" alt="" />
    </div>
    <div class="content-container">
      <div class="title">
        <p>本周盘点</p>
        <p>{{ title.substring(4).trim() }}</p>
      </div>
      <div class="info">
        <div class="row" v-if="timelineCounts">
          <b>关联时光 </b><span>本周</span> {{ timelineCounts.rangeCount }}，<span>累计</span>
          {{ timelineCounts.totalCount }}
        </div>
        <div class="row">
          <b>最多互动 </b>
          <template v-if="interactions">
            <router-link v-for="link in interactions" :key="link.uid" to="/"
              >{{ link.nickname }}
            </router-link>
          </template>
          <template v-else><span style="font-weight: normal">暂无</span></template>
        </div>
      </div>
    </div>
    <share-bottom></share-bottom>
  </div>
</template>
<script>
import shareBottom from '../common/_share-bottom.vue';

export default {
  name: 'page-sys-weekly-report',
  components: {
    shareBottom,
  },
  data() {
    return {
      title: '',
      interactions: null,
      timelineCounts: null,
    };
  },
  computed: {
    id() {
      return +this.$route.query.id;
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      const url = `api/notice/${this.id}`;
      this.$request.get(url).then((res) => {
        const {
          data: { extend },
        } = res;
        this.title = extend.title;
        this.timelineCounts = extend.timeline;
        this.interactions = extend.top3;
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.wr-container {
  .top-bg {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .content-container {
    padding: 70px 0 0 70px;
    font-size: 48px;

    .info {
      margin-top: 60px;
    }

    .row {
      font-weight: bold;
      margin-top: 20px;

      font-size: 36px;

      &:first-child {
        margin-top: 0;
      }

      span {
        color: #4386b5;
        font-weight: normal;
      }

      a {
        color: #347caf;
        font-weight: bold;
      }
    }
  }

  .bottom-bt {
    @include saveAreaBottom;
    width: 100%;
    height: 98px;
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 32px;
    font-weight: bold;

    // .inner {
    //   line-height: 98px;
    //   position: relative;
    //   text-align: center;

    //   .svgicon {
    //     position: absolute;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     left: 32px;
    //   }
    // }
    @include flex-box;

    .bt-item {
      @include flex-box;
      justify-content: center;
      align-items: center;
      width: 100%;
      p {
        margin-left: 20px;
      }
      font-weight: bold;
      flex: 1;
      line-height: 98px;
      text-align: center;
      font-size: 32px;
    }
  }
}
</style>
