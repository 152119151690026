import { render, staticRenderFns } from "./_share-bottom.vue?vue&type=template&id=48f40688&scoped=true&"
import script from "./_share-bottom.vue?vue&type=script&lang=js&"
export * from "./_share-bottom.vue?vue&type=script&lang=js&"
import style0 from "./_share-bottom.vue?vue&type=style&index=0&id=48f40688&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48f40688",
  null
  
)

export default component.exports